<template>
  <div class="d-flex flex-column overflow-auto vh-100">
    <sticky-header :title="$route.meta.title"></sticky-header>
    <div class="flex-fill p-3">
      <b-row class="mb-2">
        <b-col cols="12" md="9">
          <b-form-input debounce="500" @update="load" placeholder="Search" type="text" v-model="query"/>
        </b-col>
        <b-col cols="12" md="3">
          <b-button block @click="filtersShown = !filtersShown" variant="dark">Filters <fa-icon class="ml-2" icon="chevron-down" v-if="!filtersShown"/><fa-icon class="ml-2" icon="chevron-up" v-else/></b-button>
        </b-col>
      </b-row>
      <b-card v-if="filtersShown">
        <b-row>
          <b-col cols="12" md="4">
            <b-form-group>
              <template #label>
                <b-checkbox class="mb-0" v-model="cleanTypeEnabled"><label class="mb-0" for="cleanType">Clean Type</label></b-checkbox>
              </template>
              <b-form-select :disabled="!cleanTypeEnabled" id="cleanType" v-model="selectedCleanType" :options="['All', 'Property', 'Customer']"/>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="4">
            <b-form-group>
              <template #label>
                <b-checkbox class="mb-0" v-model="dateEnabled"><label class="mb-0" for="date_start">Start Date</label></b-checkbox>
              </template>
              <b-form-datepicker :disabled="!dateEnabled" id="date_start" v-model="selectedDateStart"/>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="4">
            <b-form-group>
              <template #label>
                <b-checkbox class="mb-0" v-model="dateEnabled"><label class="mb-0" for="date_end">End Date</label></b-checkbox>
              </template>
              <b-form-datepicker :disabled="!dateEnabled" id="date_end" v-model="selectedDateEnd"/>
            </b-form-group>
          </b-col>
        </b-row>
      </b-card>
      <div class="d-flex flex-column mt-5 py-3 justify-content-center" v-if="loading">
        <b-spinner class="align-self-center" variant="primary"/>
        <p class="align-self-center mt-1 mb-0">Loading...</p>
      </div>
      <b-card class="text-center mt-2 py-5" v-if="!loading && formattedData.length === 0">
        <p class="h3 font-weight-bold">No jobs found.</p>
        <p class="text-muted mb-0">Could not find any completed jobs in the selected time period.</p>
      </b-card>
      <div v-show="!loading" style="margin-top: 20px">
        <b-table outlined fixed foot-clone :items="grandTotals" :fields="['grand_totals', 'grand_total_charge', 'grand_total_hours']">
          <template #foot(grand_totals)="">
            <span class="text-primary">Grand Total</span>
          </template>
          <template #foot(grand_total_charge)="">
            {{ displayMoney(grandTotals.grand_total_charge) }}
          </template>
          <template #foot(grand_total_hours)="">
            {{ (Math.floor(grandTotals.grand_total_hours / 60) + 'h ' + Math.floor(grandTotals.grand_total_hours % 60) + 'm') }}
          </template>
          <template #foot()="">
            <span></span>
          </template>
        </b-table>
      </div>
      <b-list-group class="mb-4 mt-2" v-show="!loading" :key="key" v-for="(item, key) in formattedData">
        <div>
          <b-card class="mb-2">
            <div class="d-flex flex-row justify-content-between mb-3">
              <h4>{{ item.name }}</h4>
              <div class="pr-1">
                <b-button v-if="item.type === 'Property'" class="mb-md-0 ml-md-1" @click.prevent="viewProperty(item.id)" variant="primary" v-b-popover.top.hover="`View Property`"><fa-icon icon="house-user"/></b-button>
                <b-button v-else class="mb-md-0 ml-md-1" @click.prevent="viewCustomer(item.id)" variant="primary" v-b-popover.top.hover="`View Customer`"><fa-icon icon="user"/></b-button>
              </div>
            </div>
            <b-table outlined fixed foot-clone :items="formatJobs(item.jobs)" :fields="['team_name', 'hourly_rate', 'charge', 'date', 'duration', 'expected_duration', 'actions']">
              <template #cell(hourly_rate)="row">
                {{ displayMoney(row.item.hourly_rate) }}
              </template>
              <template #cell(charge)="row">
                {{ displayMoney(row.item.charge) }}
              </template>
              <template #cell(actions)="row">
                <div class="align-self-center d-flex flex-md-row flex-column">
                  <b-button class="mb-md-0 ml-md-1" target="_blank" :href="'/jobs/details/' + row.item.id" variant="primary" v-b-popover.top.hover="`View Clean`"><fa-icon icon="eye"/></b-button>
                  <b-button class="mb-md-0 ml-md-1" target="_blank" :href="'/teams/update/' + row.item.team_id" variant="primary" v-b-popover.top.hover="`View Team`"><fa-icon icon="users"/></b-button>
                </div>
              </template>
              <template #foot(team_name)="">
                <span class="text-primary">Total</span>
              </template>
              <template #foot(charge)="">
                {{ displayMoney(item.total_charge) }}
              </template>
              <template #foot(duration)="">
                {{ (Math.floor(item.total_hours/60) + 'h ' + (item.total_hours%60) + 'm') }}
              </template>
              <template #foot(expected_duration)="">
                {{ (Math.floor(item.total_expected_hours/60) + 'h ' + (item.total_expected_hours%60) + 'm') }}
              </template>
              <template #foot()="">
                <span></span>
              </template>
            </b-table>
          </b-card>
        </div>
      </b-list-group>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import StickyHeader from '../components/StickyHeader.vue'
import { mapActions, mapState } from 'vuex'
import map from "lodash/map";

export default {
  components: {StickyHeader},
  data() {
    return {
      query: '',
      filtersShown: false,
      cleanTypeEnabled: false,
      dateEnabled: false,
      selectedCleanType: undefined,
      selectedDateStart: undefined,
      selectedDateEnd: undefined,
      formattedData: [],
      grandTotals: []
    };
  },
  async mounted() {
    if (this.$route.meta.modalId) {
      this.$bvModal.show(this.$route.meta.modalId)
    }

    await this.load();
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('jobs', ['data', 'loading']),
  },
  methods: {
    ...mapActions('jobs', ['fetchReport']),
    moment,
    pluralise(count, term) {
      if (count === 1) return term;
      return `${term}s`;
    },
    viewProperty(id) {

      if (!(this.$route.name === 'properties.update' && this.$route.params.propertyId === id)) {
        this.$router.push({ name: 'properties.update', params: { propertyId: id } });
      }
    },
    viewCustomer(id) {
      if (!(this.$route.name === 'customers.update' && this.$route.params.customerId === id)) {
        this.$router.push({ name: 'customers.update', params: { customerId: id } });
      }
    },
    formatJobs(jobs) {
      return map(jobs, job => ({
        id: job.job.id,
        hourly_rate: job.hourly_rate,
        charge: job.charge,
        team_id: job.team_id,
        team_name: job.team_name,
        date: moment(job.job.started_at).format('DD/MM/YYYY'),
        duration: (Math.floor(job.job.duration/60) + 'h ' + (job.job.duration%60) + 'm'),
        expected_duration: (Math.floor((job.job.duration_min * job.job.rota.team.members.length)/60) + 'h ' + ((job.job.duration_min * job.job.rota.team.members.length)%60) + 'm'),
      }));
    },
    displayMoney(money) {
      if (!money) return '£0';

      if (money !== Math.floor(money)) {
        return '£' + money.toFixed(2);
      } else {
        return '£' + money;
      }
    },
    async load() {
      const result = await this.fetchReport(Object.fromEntries(Object.entries({
        query: this.query,
        clean_type: this.cleanTypeEnabled ? this.selectedCleanType : undefined,
        date_start: this.dateEnabled ? this.selectedDateStart : undefined,
        date_end: this.dateEnabled ? this.selectedDateEnd : undefined,
      }).filter(([,v]) => v !== undefined)));
      this.formattedData = map(result.items ?? [], item => ({
        id: item.item.id,
        name: item.item.name,
        type: item.item.type,
        total_charge: item.total_charge,
        total_hours: item.total_hours,
        total_expected_hours: item.total_expected_hours,
        jobs: item.jobs
      }));
      this.grandTotals = {
        grand_total_charge: result.grand_total_charge,
        grand_total_hours: result.grand_total_hours
      }
    },
  },
  watch: {
    async '$route'(newValue, value) {
      if (!value.meta.modalId && newValue.meta.modalId) {
        return this.$bvModal.show(newValue.meta.modalId);
      }

      if (newValue.name === value.name) {
        await this.load();
      }

      return this.$bvModal.hide(value.meta.modalId);
    },
    async selectedCleanType() { await this.load() },
    async cleanTypeEnabled() {},
    async dateEnabled() {},
    async selectedDateStart() { await this.load() },
    async selectedDateEnd() { await this.load() },

  }
}
</script>

<style>

</style>
